<template>
  <a-modal
    title="新建详情页滚动图"
    :width="480"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok', value) }"
    @cancel="() => { $emit('cancel') }"
  >
    <div class="clearfix">
      <a-upload
        :action="actionUrl"
        list-type="picture-card"
        :file-list="fileList"
        :data="new_multipart_params"
        :before-upload="beforeUpload"
        @preview="handlePreview"
        @change="handleChange"
      >
        <div v-if="fileList.length < 1">
          <a-icon type="plus" />
          <div class="ant-upload-text">
            上传
          </div>
        </div>
      </a-upload>
      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
    </div>
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'

import { customUploadFileName, ossToken } from '@/utils/util'
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  name: 'GoodsVIPPriceCreate',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      previewVisible: false,
      previewImage: '',
      value: undefined,
      actionUrl: '',
      fileList: [],
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      }
    }
  },
  beforeCreate () {
    ossToken().then(res => {
      this.new_multipart_params = res.multipart_params
      this.actionUrl = res.actionUrl
      console.log('自定义OSS返回的数据：', this.new_multipart_params)
    })
  },
  mounted () {
    this.$nextTick(() => {
      console.log('mounted', this.model)
      this.loadEditInfo(this.model)
    })
  },
  methods: {
    loadEditInfo (data) {
      const { form } = this
      const formData = pick(data, ['level', 'price'])
      console.log('formData', formData)
      this.id = formData.id
      formData.price = (formData.price / 100).toFixed(2)
      form.setFieldsValue(formData)
      this.loading = false
    },
    beforeUpload (file) {
      this.gallery_new_multipart_params.key = customUploadFileName(file)
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message.error('文件过大，请重新上传')
      }
      return isJpgOrPng && isLt2M
    },
    // 进口图片
    async handlePreview (file) {
      if (!file.url && !file.preview && file.originFileObj) {
        file.preview = await getBase64(file.originFileObj, () => {
        })
      }
      console.log('我要预览', file)
      this.previewImage = file.url || file.preview || file.thumbUrl
      this.previewVisible = true
    },
    handleChange ({ fileList, file }) {
      this.fileList = fileList
      console.log('我是图片', file)
      if (file.status === 'removed') {
        this.value = undefined
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        this.value = this.actionUrl + '/' + this.new_multipart_params.key
      }
    },
    handleCancel () {
      this.$router.back()
      this.previewVisible = false
      this.previewImage = {}
      this.value = undefined
    }
  }
}
</script>
