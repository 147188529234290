<template>
  <a-modal
    title="新建供应商"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="供应商名称">
              <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="法人">
              <a-input v-decorator="['contact', {rules: [{required: false, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="编码">
              <a-input v-decorator="['code', {rules: [{required: false}, {max:6, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="是否显示订单">
              <a-switch v-decorator="['is_show_order', { valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="统一社会信用代码">
              <a-input v-decorator="['credit_code', {rules: [{required: false, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="法人手机">
              <a-input v-decorator="['contact_cellphone', {rules: [{required: false, message: '必填项，请填写信息'}, {max:20, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="是否开启订单推送">
              <a-switch v-decorator="['is_open_api', { valuePropName: 'checked' }]" />
            </a-form-item>
            <a-form-item label="供应商状态" v-if="false">
              <a-select
                v-decorator="[
                  'status',
                  {
                    rules: [
                      { required: false, message: '请选择' },
                    ],
                    initialValue: '1'
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option value="1">
                  待入库
                </a-select-option>
                <a-select-option value="2">
                  已入库
                </a-select-option>
                <a-select-option value="3">
                  已注销
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
// 表单字段
const fields = ['id', 'name', 'credit_code', 'contact', 'contact_cellphone', 'status']
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 3 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    onChange (value) {
      console.log(value)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    }
  }
}
</script>
