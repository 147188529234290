<template>
  <a-card :bordered="false">
    <div class="app-container">
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
        title="关联商品列表"
        sub-title=""
        @back="() => $router.go(-1)"
      >
        <a-row type="flex">
          <a-statistic title="" :value="'组名：' + this.$route.query.name" />
        </a-row>
      </a-page-header>
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <div class="table-operator">
                <a-button type="primary" icon="plus" @click="handleCreate">添加</a-button>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <!-- 查询结果 -->
      <s-table
        ref="table"
        size="small"
        :loading="loading"
        :columns="columns"
        :data="loadData"
        rowKey="id">

        <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>

        <span slot="img" slot-scope="img">
          <a-avatar
            slot="img"
            :src="img"
            shape="square"
            @click="openPreview(img)"
          />
        </span>

        <div slot="price_render" slot-scope="text">
          {{ (text / 100).toFixed(2) | numberFormat }}
        </div>

        <span slot="tags" slot-scope="tags">
          <a-tag
            v-for="tag in tags.split(',')"
            :key="tag"
            :color="'volcano'"
          >
            {{ tag }}
          </a-tag>
        </span>

        <span slot="action" slot-scope="text, record">
          <template>
            <a-popconfirm
              title="确定要删除么？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </span>
      </s-table>

      <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
        <img alt="example" style="width: 300px; margin: 0 auto" :src="previewurl" />
      </a-modal>
      <add-group-goods-form
        ref="createModal"
        v-if="visible"
        :visible.sync="visible"
        :loading="confirmLoading"
        @cancel="handleAddCancel"
        @ok="handleOk"
      />
    </div>
  </a-card>
</template>

<style>
.table-expand {
  font-size: 0;
  padding-left: 60px;
}

.table-expand label {
  width: 100px;
  color: #99a9bf;
}

.table-expand .a-form-item {
  margin-right: 0;
  margin-bottom: 0;
}

.gallery {
  width: 80px;
  margin-right: 10px;
}

.goods-detail-box img {
  width: 100%;
}
</style>

<script>
import { STable } from '@/components'
import { SpecificationsGroupGoods, deleteSpecificationsGoods, addSpecificationsGroupGoods } from '@/api/applet_goods_group_manage'
import AddGroupGoodsForm from './AddGroupGoodsForm'
export default {
  name: 'GoodsList',
  components: {
    STable,
    AddGroupGoodsForm
  },
  data () {
    return {
      list: [],
      total: 0,
      loading: false,
      confirmLoading: false,
      visible: false,
      previewurl: '',
      previewvisible: false,
      listLoading: true,
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '显示名称',
          dataIndex: 'brief',
          ellipsis: true
        },
        {
          title: '图片',
          dataIndex: 'picture',
          scopedSlots: { customRender: 'img' }
        },
        {
          title: '条形码',
          dataIndex: 'barcode',
          ellipsis: true
        },
        {
          title: '品牌',
          dataIndex: 'brand',
          ellipsis: true
        },
        {
          title: '原价',
          dataIndex: 'tag_price',
          align: 'center',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '价格',
          dataIndex: 'price',
          align: 'center',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return SpecificationsGroupGoods(Object.assign(parameter, this.queryParam, { group_id: this.$route.params.id }))
          .then(res => {
            console.log(res.data)
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      }
    }
  },
  methods: {
    handleCreate () {
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          if (values) {
            console.log(values)
          }
          const data = { goods_id_list: [] }
          data.goods_id_list.push(values.id)
          data.group_id = this.$route.params.id
          addSpecificationsGroupGoods(data).then((res) => {
            console.log(res, '创建二级成功')
            this.visible = false
            this.confirmLoading = false
            this.$refs.table.refresh()
          })
        } else {
          console.log('values---000', errors)
          this.confirmLoading = false
        }
      })
    },
    openPreview (url) {
      if (url === undefined || url === '') {
        return
      }
      this.previewvisible = true
      this.previewurl = url
    },
    handleDelete (row) {
      const goods_id_list = [row.id]
      deleteSpecificationsGoods({ group_id: this.$route.params.id, goods_id_list: goods_id_list }).then(response => {
        this.$refs.table.refresh()
      }).catch(response => {
        console.log(response)
      })
    }
  }
}
</script>
