<template>
  <a-modal
    title="虚拟内容"
    :width="680"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['name', {rules: [{required: false, message: '请输入'}, {max:32, message: '输入超出长度限制'}]}]" placeholder="虚拟商品名称" allow-clear/>
        </a-form-item>
        <a-form-item label="内容" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }" v-decorator="['content', {rules: [{required: true, message: '请输入'}, {max:256, message: '输入超出长度限制'}]}]" placeholder="虚拟商品内容" allow-clear v-model="virtual_content"/>
        </a-form-item>
        <a-form-item label="内容开启" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-checkbox v-decorator="['is_open', {rules: [{required: false, message: '请输入'}]}]" v-model="show_conent">
            生成二维码
          </a-checkbox>
        </a-form-item>
        <a-form-item label="二维码" :labelCol="labelCol" :wrapperCol="wrapperCol" v-if="show_conent && virtual_content">
          <vue-qr id="vueQr" :text="virtual_content" :size="200" style="margin: 0 auto;display: block;"></vue-qr>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import VueQr from 'vue-qr'
export default {
  name: 'LogisticsForm',
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    VueQr
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      virtual_content: undefined,
      show_virtual: false
    }
  }
}
</script>
