<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-form-item label="级别" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            style="width: 140px"
            placeholder="选择"
            v-decorator="['level', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
          >
            <a-select-option :value="1">
              一级类目
            </a-select-option>
            <a-select-option :value="2">
              二级类目
            </a-select-option>
            <a-select-option :value="3">
              三级类目
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="编号" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
            v-decorator="['code', {rules: [{ required: true, message: '请输入' }]}]" />
        </a-form-item>
        <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['name', {rules: [{required: true, message: '请输入'}]}]" />
        </a-form-item>
        <a-form-item label="图片路径" :labelCol="labelCol" :wrapperCol="wrapperCol" v-show="false">
          <a-input v-decorator="['picture', {rules: [{required: false, message: '请输入'}]}]" />
        </a-form-item>
        <a-form-item label="图片" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <div class="clearfix" style="display: inline-block">
            <a-upload
              name="file"
              accept="image/png, image/jpeg, image/jpg"
              :action="actionUrl"
              list-type="picture-card"
              :file-list="fileList"
              :data="new_multipart_params"
              :before-upload="beforeUpload"
              @preview="handlePreview"
              @change="handleChange"
            >
              <div v-if="fileList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  图片上传
                </div>
              </div>
            </a-upload>
            <span v-if="false">提示：图片宽高比为1:1</span>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>

import { customUploadFileName, ossToken } from '@/utils/util'
export default {
  name: 'GoodsCategory',
  props: {
    record: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      previewVisible: false,
      previewImage: '',
      actionUrl: '',
      fileList: [],
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      }
    }
  },
  beforeCreate () {
    ossToken().then(res => {
      this.new_multipart_params = res.multipart_params
      this.actionUrl = res.actionUrl
      console.log('自定义OSS返回的数据：', this.new_multipart_params, this.actionUrl)
    })
  },
  methods: {
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    beforeUpload (file) {
      this.new_multipart_params.key = customUploadFileName(file)
      // this.form.setFieldsValue({ icon_url: this.new_multipart_params.key })
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('文件过大，请重新上传')
      }
      console.log('--------------------------------------', isJpgOrPng, isLt2M)
      return isJpgOrPng && isLt2M
    },
    // 进口图片
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj, () => {
        })
      }
      console.log('我要预览', file)
      this.previewImage = file.url || file.preview || file.thumbUrl
      this.previewVisible = true
    },
    handleChange ({ fileList, file }) {
      this.fileList = fileList
      if (file.status === 'removed') {
        this.form.setFieldsValue({ picture: '' })
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        this.form.setFieldsValue({ picture: this.actionUrl + '/' + this.new_multipart_params.key })
      }
    },
    handleCancel: function () {
      this.previewVisible = false
    }
  }
}
</script>
