<template>
  <a-card :bordered="false">
    <div class="app-container">

      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="5" :sm="24">
              <a-form-item label="供应商">
                <a-input v-model="queryParam.supplier" allow-clear placeholder="请输入供应商名称" />
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="条形码">
                <a-input v-model="queryParam.barcode" allow-clear placeholder="请输入商品条形码" />
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="商品名称">
                <a-input v-model="queryParam.name" allow-clear placeholder="请输入商品名称" />
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="标签">
                <a-select mode="tags" placeholder="请选择" @change="handleStatusChange">
                  <a-select-option v-for="(item, index) in allTags" :value="item.id + ''" :key="index"> {{ item.name }} </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="是否编辑">
                <a-select placeholder="请选择" @change="handleEditChange" allowClear>
                  <a-select-option v-for=" (item, index) in selectedEditList" :value="item.is_edited" :key="index"> {{ item.title }} </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <div class="table-operator">
                <a-button type="primary" icon="search" @click="$refs.table.refresh(true)">查找</a-button>
                <a-button type="primary" icon="plus" @click="handleCreate">添加</a-button>
                <a-button type="primary" icon="import" @click="handleImport">导入</a-button>
                <a-button type="primary" icon="export" @click="handleExport">导出</a-button>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <!-- 查询结果 -->
      <s-table
        ref="table"
        size="small"
        :loading="loading"
        :columns="columns"
        :pageNum="pageNum"
        :data="loadData"
        :scroll="{ x: 1000 }"
        rowKey="id">

        <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
        <span slot="img" slot-scope="img">
          <a-avatar
            slot="img"
            :src="img"
            shape="square"
            @click="openPreview(img)"
          />
        </span>
        <div slot="count" slot-scope="text" style="min-width: 100px; max-width: 120px; text-align: right; padding-right: 10px;">
          {{ text | numberFormat }}
        </div>
        <div slot="edited" slot-scope="text">
          <a-tag :color="getTagColor(text)">
            {{ text? '已编辑': '未编辑' }}
          </a-tag>
        </div>
        <a-popover title="标签" slot="tags" slot-scope="tags">
          <template slot="content">
            <p v-for="tag in tags" :key="tag.id" style="color: red">{{ tag.name }}</p>
          </template>
          <span>
            <a-tag
              v-for="tag in tags"
              :key="tag.id"
              color="red">
              {{ tag.name }}
            </a-tag>
          </span>
        </a-popover>

        <div slot="price_render" slot-scope="text" style="min-width: 100px; max-width: 150px; text-align: right">
          {{ (text / 100).toFixed(2) | numberFormat }}
        </div>
        <div slot="show_on_index" slot-scope="text, record">
          <a-switch @change="showConfirm(record.id, text, 'show_on_index')" :checked="text"/>
        </div>
        <div slot="is_sale" slot-scope="text, record">
          <a-switch @change="showConfirm(record.id, text, 'is_sale')" :checked="text"/>
        </div>
        <div slot="is_new" slot-scope="text, record">
          <a-switch @change="showConfirm(record.id, text, 'is_new')" :checked="text"/>
        </div>
        <div slot="is_hot" slot-scope="text, record">
          <a-switch @change="showConfirm(record.id, text, 'is_hot')" :checked="text"/>
        </div>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleChangeCount(record)">修改数量</a>
            <!--            <a-divider type="vertical" />-->
            <!--            <a @click="handleVipPrice(record)">会员价</a>-->
            <a-divider type="vertical" />
            <a-dropdown>
              <a class="ant-dropdown-link">
                更多 <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="handleUpdate(record)">编辑</a>
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm
                    title="确定要删除么？"
                    ok-text="确定"
                    cancel-text="取消"
                    @confirm="handleDelete(record)"
                  >
                    <a>删除</a>
                  </a-popconfirm>
                </a-menu-item>
                <a-menu-item>
                  <a @click="handleCountRecord(record)">数量变动记录</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="showDrawer(record)">标签设置</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
        </span>
      </s-table>

      <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
        <img alt="example" style="width: 300px; margin: 0 auto" :src="previewurl" />
      </a-modal>
      <create-goods-import-form
        ref="goodsImportModal"
        v-if="import_form_visible"
        :visible.sync="import_form_visible"
        :loading="importConfirmLoading"
        @cancel="handleImportCancel"
        @ok="handleImportOk" />
      <change-count-form
        ref="goodsChangeCountModal"
        v-if="visible_change"
        :visible.sync="visible_change"
        :loading="countConfirmLoading"
        :model="goodsDetail"
        @cancel="handleChangeCountCancel"
        @ok="handleChangeCountOk" />
      <a-drawer
        title="标签设置"
        placement="right"
        :closable="false"
        :visible="DrawerVisible"
        @close="onCloseDrawer">
        <p :style="{ marginRight: '8px' }">请选择商品标签:</p>
        <template>
          <span v-for="(tag, index) in allTags" :key="tag.id + index">
            <a-checkable-tag
              style="margin-bottom: 10px; margin-right: 10px;"
              :key="tag.id"
              :checked="selectedTags.indexOf(tag.name) > -1"
              @change="checked => handleTagChange(tag, checked)"
            >
              {{ tag.name }}
            </a-checkable-tag>
          </span>
        </template>
      </a-drawer>
    </div>
  </a-card>
</template>
<script>
import {
  listGoods,
  deleteGoods,
  editGoods,
  goodsDataImport,
  goodsDataCompressImport,
  goods_tag_list, goods_related_tag_delete, goods_related_tag_create
} from '@/api/applet_goods'
import { STable } from '@/components'
import CreateGoodsImportForm from './CreateGoodsImportForm'
import ChangeCountForm from './ChangeCountForm'
import storage from 'store'
import { UNITS } from '@/store/mutation-types'
import { common_unit_list } from '@/api/common'
import * as XLSX from 'xlsx'

const { MessageBox } = require('element-ui')
export default {
  name: 'GoodsList',
  components: {
    STable,
    CreateGoodsImportForm,
    ChangeCountForm
  },
  data () {
    return {
      pageNum: 1,
      list: [],
      total: 0,
      DrawerVisible: false,
      allTags: [],
      selectedTags: [],
      selectedEditList: [
        {
        is_edited: '',
        title: '全部'
        },
        {
          is_edited: 'true',
          title: '已编辑'
        },
        {
          is_edited: 'false',
          title: '未编辑'
        }
      ],
      goods_id: undefined,
      loading: false,
      import_form_visible: false,
      importConfirmLoading: false,
      countConfirmLoading: false,
      units: {},
      goods_type: this.$Dictionaries.goods_type,
      previewurl: '',
      previewvisible: false,
      listLoading: true,
      queryParam: {},
      goodsDetail: undefined,
      visible_change: false,
      downloadLoading: false,
      dataSource: [],
      // 表头
      columns: [
        {
          title: '序号',
          width: '100px',
          dataIndex: 'serial',
          fixed: 'left',
          scopedSlots: { customRender: 'serial' },
          formatter: undefined
        },
        {
          title: '商品名称',
          dataIndex: 'name',
          width: '150px',
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '图片',
          dataIndex: 'picture',
          width: '100px',
          scopedSlots: { customRender: 'img' }
        },
        {
          title: '微信展示名称',
          dataIndex: 'brief',
          width: '200px',
          ellipsis: true
        },
        {
          title: '供应商',
          dataIndex: 'supplier',
          width: '150px',
          ellipsis: true
        },
        {
          title: '条形码',
          dataIndex: 'barcode',
          width: '150px',
          ellipsis: true
        },
        {
          title: '是否编辑',
          dataIndex: 'is_edited',
          width: 100,
          scopedSlots: { customRender: 'edited' },
          formatter: value => value ? '已编辑' : '未编辑'
        },
        {
          title: '品牌',
          dataIndex: 'brand',
          width: '150px',
          ellipsis: true
        },
        {
          title: '商品类型',
          dataIndex: 'goods_type',
          width: '150px',
          ellipsis: true,
          customRender: (text) => this.$Dictionaries.goods_type[text] ? this.$Dictionaries.goods_type[text] : '-',
          formatter: value => this.$Dictionaries.goods_type[value] ? this.$Dictionaries.goods_type[value] : '-'
        },
        {
          title: '标签',
          dataIndex: 'tags',
          width: 120,
          align: 'left',
          ellipsis: true,
          scopedSlots: { customRender: 'tags' },
          formatter: value => value.map(item => item.name).join(',')
        },
        {
          title: '成本价',
          dataIndex: 'cost_price',
          align: 'right',
          width: '150px',
          scopedSlots: { customRender: 'price_render' },
          formatter: value => `${(value / 100).toFixed(2)}`
        },
        {
          title: '建议售价',
          dataIndex: 'suggested_price',
          align: 'right',
          width: '150px',
          scopedSlots: { customRender: 'price_render' },
          formatter: value => `${(value / 100).toFixed(2)}`
        },
        {
          title: '原价',
          dataIndex: 'tag_price',
          align: 'right',
          width: '150px',
          scopedSlots: { customRender: 'price_render' },
          formatter: value => `${(value / 100).toFixed(2)}`
        },
        {
          title: '现价',
          dataIndex: 'price',
          align: 'right',
          width: '150px',
          scopedSlots: { customRender: 'price_render' },
          formatter: value => `${(value / 100).toFixed(2)}`
        },
        {
          title: '数量',
          dataIndex: 'count',
          align: 'right',
          width: 120,
          scopedSlots: { customRender: 'count' }
        },
        {
          title: '销售量',
          dataIndex: 'sale_count',
          align: 'right',
          width: 120,
          scopedSlots: { customRender: 'count' }
        },
        {
          title: '近30天访问量',
          dataIndex: 'month_visit_count',
          align: 'right',
          width: 120,
          scopedSlots: { customRender: 'count' }
        },
        {
          title: '总访问量',
          dataIndex: 'all_visit_count',
          align: 'right',
          width: 120,
          scopedSlots: { customRender: 'count' }
        },
        {
          title: '单位',
          dataIndex: 'unit',
          align: 'center',
          width: '100px',
          customRender: (text) => this.units[text] ? this.units[text] : '-',
          formatter: value => this.units[value] ? this.units[value] : '-'
        },
        {
          title: '上架时间',
          dataIndex: 'sale_time',
          align: 'center',
          width: '180px',
          customRender: (text) => text === '1970-01-01 00:00:00' || text === '' ? '-' : text,
          formatter: value => value === '1970-01-01 00:00:00' || value === '' ? '-' : value
  },
        {
          title: '是否上架',
          dataIndex: 'is_sale',
          align: 'center',
          width: '120px',
          scopedSlots: { customRender: 'is_sale' },
          formatter: value => value ? '是' : '否'
        },
        {
          title: '是否热销',
          dataIndex: 'is_hot',
          width: '100px',
          scopedSlots: { customRender: 'is_hot' },
          formatter: value => value ? '是' : '否'
        },
        {
          title: '是否新品',
          width: '100px',
          dataIndex: 'is_new',
          scopedSlots: { customRender: 'is_new' },
          formatter: value => value ? '是' : '否'
        },
        {
          title: '是否首页',
          dataIndex: 'show_on_index',
          width: '100px',
          scopedSlots: { customRender: 'show_on_index' },
          formatter: value => value ? '是' : '否'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          width: '280px',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return listGoods(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data)
            this.dataSource = res.data.entries
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      }
    }
  },
  created () {
    this.setCurrentPage()
  },
  mounted () {
    this.loadUnits()
    this.loadAllTags()
  },
  methods: {
    setCurrentPage () {
      // const page = sessionStorage.getItem('CurrentPage')
      const page = this.$store.getters.pop
      if (page) {
        console.log(page)
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        } else {
          this.queryParam = {}
          this.$store.dispatch('clear')
        }
      }
    },
    upDownTitle (record) {
      return '确定要' + (record.is_sale ? '下架' : '上架') + '吗？'
    },
    handleStatusChange (value) {
      this.queryParam.tag_ids = value.join(',')
    },
    handleEditChange (value) {
      if (value === '') {
        this.queryParam.is_edited = undefined
      } else {
        this.queryParam.is_edited = value
      }
    },
    handleCreate () {
      this.$router.push({ path: '/applet_goods/create' })
    },
    onChangeSwitch (id, checked) {
      console.log(id, checked)
    },
    showConfirm (id, checked, tag) {
      console.log(id, checked)
      const goods = {}
      goods[tag] = !checked
      this.editGoods(goods, id)
    },
    editGoods (goods, id) {
      this.loading = true
      editGoods(goods, id)
        .then(response => {
          if (response.code === 1000) {
            this.$refs.table.refresh()
          }
        })
        .catch(response => {
          console.log(response)
        }).finally(() => {
          this.loading = false
      })
    },
    handleUpdate (row) {
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({ path: '/applet_goods/edit', query: { id: row.id } })
    },
    handleChangeCount (row) {
      this.visible_change = true
      this.goodsDetail = row
    },
    handleVipPrice (row) {
      sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({ path: '/applet_goods/vip_price', query: { id: row.id, barcode: row.barcode } })
    },
    handleGallery (row) {
      sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({ path: '/applet_goods/gallery', query: { id: row.id } })
    },
    handleSale (row) {
      console.log(row)
      editGoods({ is_sale: !row.is_sale }, row.id)
        .then(response => {
          this.$refs.table.refresh()
        })
        .catch(response => {
          MessageBox.alert('错误：' + response, '警告', {
            confirmButtonText: '确定',
            type: 'error'
          })
        })
    },
    openPreview (url) {
      if (url === undefined || url === '') {
        return
      }
      this.previewvisible = true
      this.previewurl = url
    },
    handleDelete (row) {
      deleteGoods(row.id).then(response => {
        this.$refs.table.refresh()
      }).catch(response => {
        console.log(response)
      })
    },
    handleCountRecord (row) {
      console.log(row)
      sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({ path: '/applet_goods/count_record', query: { goods_id: row.id, barcode: row.barcode, goods_name: row.name, brief: row.brief } })
    },
    showDrawer (record) {
      this.DrawerVisible = true
      this.goods_id = record.id
      this.selectedTags = record.tags.map((item) => item.name) || []
    },
    loadAllTags () {
      goods_tag_list().then((res) => {
        this.allTags = res.data.entries || []
      })
    },
    loadUnits () {
      this.units = storage.get(UNITS)
      if (this.units === undefined || this.units === {}) {
        common_unit_list().then(res => {
          const unit = {}
          res.data.forEach(function (item, index, self) {
            unit[item.code] = item.name
          })
          storage.set(UNITS, unit, 7 * 24 * 60 * 60 * 1000)
        })
      }
    },
    handleTagChange (tag, checked) {
      const { selectedTags } = this
      const nextSelectedTags = checked
        ? [...selectedTags, tag.name]
        : selectedTags.filter(t => t !== tag.name)
      console.log('你选的标签: ', nextSelectedTags)
      if (checked) {
        goods_related_tag_create({ goods_id: this.goods_id, tag_id: tag.id }).then(() => { this.selectedTags = nextSelectedTags })
      } else {
        goods_related_tag_delete({ goods_id: this.goods_id, tag_id: tag.id }).then(() => { this.selectedTags = nextSelectedTags })
      }
      this.$refs.table.refresh()
    },
    onCloseDrawer () {
      this.DrawerVisible = false
    },
    handleExport () {
      // 创建一个临时数组用于存储格式化后的数据
      const formattedData = this.dataSource.map((item, index) => {
        const new_columns = this.columns.reduce((obj, col) => {
          // 使用 formatter 函数格式化数据值，如果存在的话
          if (col.dataIndex === 'serial') {
            obj[col.dataIndex] = index + 1
          } else if (col.dataIndex === 'action') {
            obj[col.dataIndex] = undefined
          } else if (col.dataIndex) {
            obj[col.dataIndex] = col.formatter ? col.formatter(item[col.dataIndex]) : item[col.dataIndex]
          }
          return obj
        }, {})
        return new_columns
      })
      // 自定义表头映射
      const headerMapping = this.columns.map(col => col.title)

      // 创建工作表并添加数据
      const ws = XLSX.utils.json_to_sheet(formattedData, {
        skipHeader: false // 跳过默认的header生成
      })

      // 手动添加表头行
      if (headerMapping.length) {
        const range = XLSX.utils.decode_range(ws['!ref'])
        const C = range.s.c
        const R = range.s.r - 1 // 将新行插入到最上方
        headerMapping.forEach((title, i) => {
          ws[XLSX.utils.encode_cell({ c: C + i, r: R })] = { t: 's', v: title }
        })

        ws['!ref'] = XLSX.utils.encode_range({ s: { c: range.s.c, r: R }, e: range.e })
      }
      // 创建一个新的工作簿并添加工作表
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, '商品')

      // 导出为文件
      XLSX.writeFile(wb, '商品列表.xlsx')
    },
    handleImport () {
      this.import_form_visible = true
    },
    handleImportCancel () {
      this.import_form_visible = false
      const form = this.$refs.goodsImportModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleImportOk (fileList) {
      const form = this.$refs.goodsImportModal.form
      this.importConfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          const formData = new FormData()
          formData.append('file', fileList[0])
          goodsDataCompressImport(formData).then(res => {
            console.log(res)
            // 刷新表格
            this.$refs.table.refresh()
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.import_form_visible = false
            this.importConfirmLoading = false
            form.resetFields()
          })
          if (formData) {
            return
          }
          goodsDataImport(formData).then(res => {
            console.log(res)
            // 刷新表格
            this.$refs.table.refresh()
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.import_form_visible = false
            this.importConfirmLoading = false
            form.resetFields()
          })
        } else {
          this.importConfirmLoading = false
        }
      })
    },
    handleChangeCountCancel () {
      this.visible_change = false
      const form = this.$refs.goodsChangeCountModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleChangeCountOk () {
      const form = this.$refs.goodsChangeCountModal.form
      this.countConfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          editGoods(values, this.goodsDetail.id).then(res => {
            console.log(res)
            // 刷新表格
            this.$refs.table.refresh()
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.visible_change = false
            this.countConfirmLoading = false
            form.resetFields()
          })
        } else {
          this.countConfirmLoading = false
        }
      })
    },
    getTagColor (edited) {
      return edited ? 'green' : 'red'
    }
  }
}
</script>

<style scoped>
.table-expand {
  font-size: 0;
  padding-left: 60px;
}

.table-expand label {
  width: 100px;
  color: #99a9bf;
}

.table-expand .a-form-item {
  margin-right: 0;
  margin-bottom: 0;
}

.gallery {
  width: 80px;
  margin-right: 10px;
}

.goods-detail-box img {
  width: 100%;
}
   /deep/.ant-select-selection--multiple .ant-select-selection__rendered {
     margin-left: 5px;
     margin-bottom: -3px;
     height: auto;
     max-height: 30px;
     max-width: 200px;
     overflow: auto;
     overflow-y: hidden;
   }
/deep/.ant-select-selection--multiple .ant-select-selection__choice {
  overflow: initial;
}
   /deep/.ant-select ul,
   .ant-select ol {
     display: flex;
   }
/deep/.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  margin-top: 3px;
  height: 22px;
  line-height: 22px;
  font-size: 14px;
  width: auto;
  max-height: 100%;
}
/deep/.ant-select-search--inline .ant-select-search__field__wrap{
  max-width: 0px !important;
}
/deep/.ant-select-selection__rendered::-webkit-scrollbar {
  /*滚动条整体样式*/
  height: 3px;
}
/deep/.ant-select-selection__rendered::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: lightcoral;
}
/deep/.ant-select-selection__rendered::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, .1);
  border-radius: 10px;
  background: #ededed;
}
</style>
