<template>

  <a-modal
    title="修改头像"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :width="800"
    :footer="null"
    @cancel="cancelHandel">
    <a-row>
      <a-col :xs="24" :md="12" :style="{height: '350px'}">
        <vue-cropper
          ref="cropper"
          :img="options.img"
          :info="true"
          :autoCrop="options.autoCrop"
          :autoCropWidth="options.autoCropWidth"
          :autoCropHeight="options.autoCropHeight"
          :fixedBox="options.fixedBox"
          @realTime="realTime"
        >
        </vue-cropper>
      </a-col>
      <a-col :xs="24" :md="12" :style="{height: '350px'}">
        <div class="avatar-upload-preview">
          <img :src="previews.url" :style="previews.img"/>
        </div>
      </a-col>
    </a-row>
    <br>
    <a-row>
      <a-col :lg="2" :md="2">
        <a-upload
          name="file"
          accept="image/png, image/jpeg, image/jpg"
          :beforeUpload="beforeUpload"
          :showUploadList="false"
          :data="new_multipart_params"
          :action="actionUrl"
          @change="handleChange"
        >
          <a-button icon="upload">选择图片</a-button>
        </a-upload>
      </a-col>
      <a-col :lg="{span: 1, offset: 2}" :md="2">
        <a-button icon="plus" @click="changeScale(1)"/>
      </a-col>
      <a-col :lg="{span: 1, offset: 1}" :md="2">
        <a-button icon="minus" @click="changeScale(-1)"/>
      </a-col>
      <a-col :lg="{span: 1, offset: 1}" :md="2">
        <a-button icon="undo" @click="rotateLeft"/>
      </a-col>
      <a-col :lg="{span: 1, offset: 1}" :md="2">
        <a-button icon="redo" @click="rotateRight"/>
      </a-col>
      <a-col :lg="{span: 2, offset: 6}" :md="2">
        <a-button type="primary" @click="save">确定</a-button>
      </a-col>
    </a-row>
  </a-modal>

</template>
<script>
import { customUploadFileName, ossToken } from '@/utils/util'
/* function getBase64 (img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
} */
export default {
  props: {
    visible: {
      type: Boolean,
      required: false
    },
    model: {
      type: Object,
      default: () => null
    },
    // 表单校验用字段
    // eslint-disable-next-line
    value: {
      type: String
    }
  },
  data () {
    return {
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      actionUrl: '',
      id: null,
      imageUrl: '',
      confirmLoading: false,
      fileList: [],
      uploading: false,
      options: {
        // img: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        img: '/assets/logo.svg',
        autoCrop: true,
        autoCropWidth: 200,
        autoCropHeight: 200,
        fixedBox: true
      },
      previews: {}
    }
  },
  mounted () {
    ossToken().then(res => {
      this.new_multipart_params = res.multipart_params
      this.actionUrl = res.actionUrl
      console.log('自定义OSS返回的数据：', this.new_multipart_params)
    })
  },
  methods: {
    handleChange (info) {
      console.log(info)
      if (info.file.status === 'uploading') {
        this.confirmLoading = true
        return
      }

      if (info.file.status === 'error') {
        this.$message.error('上传头像失败')
      }
      if (info.file.status === 'done') {
        console.log(info.file, 'end')
        this.imageUrl = this.actionUrl + '/' + this.new_multipart_params.key
      }
    },
    cancelHandel () {
      this.$emit('cancel')
    },
    changeScale (num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    rotateLeft () {
      this.$refs.cropper.rotateLeft()
    },
    rotateRight () {
      this.$refs.cropper.rotateRight()
    },
    beforeUpload (file) {
      const reader = new FileReader()
      // 把Array Buffer转化为blob 如果是base64不需要
      // 转化为base64
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.options.img = reader.result
      }
      this.new_multipart_params.key = customUploadFileName(file)
      // 转化为blob
      //  reader.readAsArrayBuffer(file)
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 5

      if (!isJpgOrPng || !isLt2M) {
        this.$message.error('你只能上传JPG或者PNG格式的图片,  或者图片大小超过5MB!')
        return false
      }
    },

    // （点击保存按钮）
    save () {
      this.$emit('ok', { b64: this.options.img, url: this.imageUrl })
    },
    realTime (data) {
      this.previews = data
    }
  }
}
</script>

<style lang="less" scoped>

  .avatar-upload-preview {
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
    width: 180px;
    height: 180px;
    border-radius: 50%;
    box-shadow: 0 0 4px #ccc;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
</style>
