<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="企业名称">
              <a-input v-model="queryParam.name" placeholder="企业名称" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="3" :sm="24" v-if="false">
            <a-form-item label="状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">
                <a-select-option v-for="item in dictionaries.status" :value="Object.keys(item)[0]" :key="Object.values(item)[0]"> {{ Object.values(item)[0] }} </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="3" :sm="24">
            <a-form-item label="法人">
              <a-input v-model="queryParam.contact" placeholder="法人" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="3" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd" v-action:add>新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :pageNum="pageNum"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)" v-action:edit>编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)">
            <a v-action:delete>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <max-role-create
      v-if="maxPermissionVisible"
      ref="maxRoleModal"
      :visible="maxPermissionVisible"
      :loading="maxPermissionLoading"
      :model="maxPermissionConfigmdl"
      @cancel="handleMaxPermissionConfigCancel"
      @ok="handleMaxPermissionConfigOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import { supplier_list, supplier_create, supplier_delete, supplier_tag_create, supplier_tag_delete, supplier_config_partial_update } from '@/api/supplier'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import MaxRoleCreate from '@/views/a-corporation/table/MaxRoleCreate'
/*
import pick from 'lodash.pick'
*/
export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    MaxRoleCreate
  },
  data () {
    return {
      configHouseLoading: false,
      configHouseVisible: false,
      dataSource: [],
      supplier_id: null,
      tip: '',
      corList: [],
      pageNum: 1,
      loading: false,
      confirmLoading: false,
      configLoading: false,
      maxPermissionVisible: false,
      maxPermissionLoading: false,
      dictionaries: Dictionaries,
      current_supplier_id: null,
      allTags: [],
      // 创建窗口控制
      visible: false,
      tags: [],
      selectedTags: [],
      mdl: {},
      configmdl: {},
      maxPermissionConfigmdl: {},
      // 高级搜索 展开/关闭
      advanced: true,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '企业名称',
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '统一社会信用代码',
          dataIndex: 'credit_code',
          ellipsis: true
        },
        {
          title: '编码',
          dataIndex: 'code',
          ellipsis: true
        },
        {
          title: '法人',
          dataIndex: 'contact'
        },
        {
          title: '法人手机',
          dataIndex: 'contact_cellphone',
          customRender: (text) => text || '--'
        },
        {
          title: '是否开启订单推送',
          dataIndex: 'is_open_api',
          align: 'center',
          width: 150,
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '是否显示订单',
          dataIndex: 'is_show_order',
          align: 'center',
          width: 150,
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return supplier_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.setCurrentPage()
  },
  methods: {
    setCurrentPage () {
      // const page = sessionStorage.getItem('CurrentPage')
      const page = this.$store.getters.pop
      if (page) {
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        } else {
          this.queryParam = {}
          this.$store.dispatch('clear')
        }
      }
    },
    handleStatusChange (value) {
      console.log(`selected ${value}`)
      this.queryParam.tag_ids = value.join(',')
    },
    handleTagChange (tag, checked) {
      console.log()
       const { selectedTags } = this
      const nextSelectedTags = checked
        ? [...selectedTags, tag.name]
        : selectedTags.filter(t => t !== tag.name)
      console.log('你选的标签: ', nextSelectedTags)
      if (checked) {
        supplier_tag_create({ supplier_id: this.current_supplier_id, tag_id: tag.id }).then(() => { this.selectedTags = nextSelectedTags })
      } else {
        supplier_tag_delete({ supplier_id: this.current_supplier_id, tag_id: tag.id }).then(() => { this.selectedTags = nextSelectedTags })
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleMaxPermissionConfigCancel () {
      this.maxPermissionVisible = false
    },
    handleMaxPermissionConfigOk (record) {
      const form = this.$refs.maxRoleModal.configform
      this.configLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          supplier_config_partial_update(values, record.id).then(res => {
            this.configLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()

            console.log('删除成功------')
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.configLoading = false
        }
      })
    },
    handleEdit (record) {
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/supplier/' + record.id })
    },
    handleDelet (record) {
      supplier_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')

        this.$refs.table.refresh()
      })
    },
    handleMaxPermissionConfig (record) {
      this.maxPermissionConfigmdl = record
      console.log(this.maxPermissionConfigmdl)
      this.maxPermissionVisible = true
      this.maxPermissionLoading = false
    },
    afterVisibleChange (val) {
      console.log('visible', val)
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
            // 新增
          supplier_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
              console.log('删除成功------')
          })
          .catch((error) => {
            console.log(error)
            this.confirmLoading = false
          })
         .finally(() => {
              this.confirmLoading = false
         })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {}
    }
  }
}
</script>
