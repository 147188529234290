<template>
  <a-drawer
    :title="'虚拟订单【' + model.order_no + '】关联内容'"
    placement="right"
    :visible="visible"
    width="1080"
    :closable="true"
    @close="() => { $emit('cancel') }"
  >
    <div class="table-page-search-wrapper" style="margin-bottom: 20px" v-if="model.status === 2 || model.status === 3">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="2" :sm="24">
            <a-button type="primary" icon="plus" @click="handleAdd">添加内容</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
      :pagination="false"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <template slot="action" slot-scope="text, record">
        <a-popconfirm
          title="确定要删除么？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="handleDelete(record)"
        >
          <a>删除</a>
        </a-popconfirm>
      </template>
    </s-table>
    <create-virtual-data-form
      ref="createModal"
      v-if="createVirtualVisible"
      :visible.sync="createVirtualVisible"
      :loading="virtualConfirmLoading"
      @cancel="handleVirtualCancel"
      @ok="handleVirtualOk"
    />
  </a-drawer>
</template>
<script>

import {
  addOrderVirtual,
  deleteOrderVirtual,
  orderVirtualList
} from '@/api/applet_shopmall_order'
import { STable } from '@/components'
import CreateVirtualDataForm from '@/views/a-applet/shop_manage/virtual/CreateVirtualDataForm'
export default {
  name: 'ValueCardConsumeRecordList',
  components: {
    STable,
    CreateVirtualDataForm
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '名称',
          ellipsis: true,
          dataIndex: 'name'
        },
        {
          title: '内容',
          ellipsis: true,
          dataIndex: 'content'
        },
        {
          title: '开启内容二维码',
          ellipsis: true,
          dataIndex: 'is_open',
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          width: '100px',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      createVirtualVisible: false,
      virtualConfirmLoading: false,
      loading: true,
      pageNum: 1,
      mdl: undefined,
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return orderVirtualList(Object.assign(parameter, this.queryParam))
          .then((res) => {
            return res.data
          })
      }
    }
  },
  created () {
    this.queryParam.order_id = this.model.id
  },
  methods: {
    handleAdd () {
      this.createVirtualVisible = true
    },
    handleVirtualCancel () {
      this.createVirtualVisible = false
      if (this.mdl !== undefined) {
        this.mdl = undefined
      }
    },
    handleVirtualOk () {
      const form = this.$refs.createModal.form
      this.virtualConfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          values.order_id = this.model.id
          if (this.mdl === undefined) {
            addOrderVirtual(values).then(res => {
              // 刷新表格
              if (res.code === 1000) {
                this.createVirtualVisible = false
                this.$refs.table.refresh()
              }
            }).finally(() => {
              this.virtualConfirmLoading = false
              if (!this.createVirtualVisible) {
                form.resetFields()
              }
            })
          }
        } else {
          this.virtualConfirmLoading = false
        }
      })
    },
    handleChangeVirtual (record) {
      this.mdl = record
      this.createVirtualVisible = true
    },
    handleDelete (record) {
      deleteOrderVirtual(record.id).then(res => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    }
  }
}
</script>
<style>
</style>
