// eslint-disable-next-line
import { UserLayout, BasicLayout, PageView } from '@/layouts'
import { bxAnaalyse } from '@/core/icons'

// eslint-disable-next-line no-unused-vars
const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view')
}

export const asyncRouterMap = [

  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: '福森商城' },
    redirect: '/dashboard',
    children: [
      // dashboard
      /* {
        path: '/dashboard',
        name: 'dashboard',
        redirect: '/dashboard/analysis',
        component: PageView,
        meta: { title: '主页', keepAlive: true, icon: 'apple', permission: [ 'dashboard' ] },
        children: [
          {
            path: '/dashboard/analysis/:pageNo([1-9]\\d*)?',
            name: 'Analysis',
            component: () => import('@/views/dashboard/emputy'),
            meta: { title: '主页', keepAlive: false, permission: [ 'dashboard' ] }
          }/!*,
          // 外部链接
          {
            path: 'https://www.baidu.com/',
            name: 'Monitor',
            meta: { title: 'menu.dashboard.monitor', target: '_blank' }
          },
          {
            path: '/dashboard/workplace',
            name: 'Workplace',
            component: () => import('@/views/dashboard/Workplace'),
            meta: { title: 'menu.dashboard.workplace', keepAlive: true, permission: [ 'dashboard' ] }
          } *!/
        ]
      }, */
      {
        path: '/dashboard',
        name: 'dashboard',
        redirect: '/dashboard/workplace',
        component: PageView,
        meta: { title: '主页', keepAlive: true, icon: 'dashboard' },
        children: [
          {
            path: '/dashboard/workplace',
            name: 'Workplace',
            component: () => import('@/views/dashboard/Workplace'),
            meta: { title: '统计', keepAlive: true }
          }
        ]
      },
      // a-supplier
      {
        path: '/supplier_list',
        name: 'corporation',
        redirect: '/supplier',
        component: PageView,
        meta: { title: '供应商', keepAlive: true, icon: bxAnaalyse, permission: ['corporation'] },
        children: [
          {
            path: '/supplier',
            name: 'CorporationInformation',
            component: () => import('@/views/a-corporation/CorporationInformation'),
            meta: { title: '供应商', keepAlive: false, permission: [ 'corporation' ] }
          },
          {
            path: '/supplier/:id',
            name: 'CorporationInformationEdit',
            hidden: true,
            component: () => import('@/views/a-corporation/table/Edit'),
            meta: { title: '供应商', keepAlive: false, permission: [ 'corporation' ] }
          }
        ]
      },
      {
        path: '/account/settings',
        component: PageView,
        redirect: '/account/settings/base',
        hidden: true,
        name: 'account',
        meta: { title: '个人页', icon: 'user', keepAlive: true, permission: [ 'user_settings' ] },
        children: [
          {
            path: '/account/settings/base',
            name: 'settings',
            component: () => import('@/views/account/settings/BaseSetting'),
            meta: { title: '个人设置', hideHeader: true, permission: [ 'user_settings' ] }
          }
        ]
      },
      {
        path: '/users',
        name: 'Users',
        redirect: '/users/userList',
        component: PageView,
        meta: { title: '用户', keepAlive: true, icon: 'user', permission: [ 'user_information', 'role', 'dingding' ] },
        children: [
          // {
          //   path: '/users/userList',
          //   name: 'User',
          //   redirect: '/users/userList/UserInformation',
          //   component: RouteView,
          //   meta: { title: '用户管理', keepAlive: false, permission: ['user_information', 'role'] }
          // }
            {
              path: '/users/userList/UserInformation',
              name: 'UserInformation',
              component: () => import('@/views/a-user/UserInformation'),
              meta: { title: '用户列表', keepAlive: false, permission: [ 'user_information' ] }
            },
            {
              path: '/users/userList/UserInformation/:id',
              name: 'UserInformationEdit',
              hidden: true,
              component: () => import('@/views/a-user/table/Edit'),
              meta: { title: '用户编辑', keepAlive: false, permission: [ 'user_information' ] }
            },
            {
              path: '/users/userList/RoleInformation',
              name: 'RoleList',
              component: () => import('@/views/a-role/RoleInformation'),
              meta: { title: '权限组配置', keepAlive: false, permission: [ 'role' ] }
            },
            {
              path: '/users/userList/RoleInformation/:id',
              name: 'RoleListEdit',
              hidden: true,
              component: () => import('@/views/a-role/table/Edit'),
              meta: { title: '权限组配置编辑', keepAlive: false, permission: [ 'role' ] }
            }
        ]
      },
      {
        path: '/zb/wms',
        component: PageView,
        redirect: '/zb/wms/warehouse',
        name: 'wms',
        meta: { title: '仓库', icon: 'stock', permission: [ 'warehouse', 'cargo_space', 'warehouse_goods', 'goods_declaration', 'goods_purchase', 'goods_purchase_declaration', 'goods_inventory', 'transfer_inventory', 'goods_loss', 'wms_inventory', 'wms_order', 'wms_picks', 'shop_statistics' ] },
        children: [
          {
            path: 'warehouse',
            name: 'wms_warehouse_list',
            component: () => import('@/views/c-wms/warehouse/WareHouse'),
            meta: { title: '仓库', permission: [ 'warehouse', 'cargo_space' ] }
          },
          {
            path: 'warehouse_edit/:id',
            name: 'wms_warehouse_list_edit',
            hidden: true,
            component: () => import('@/views/c-wms/warehouse/table/Edit'),
            meta: { title: '仓库编辑' }
          },
          {
            path: 'goods_category',
            name: 'goods_category',
            hidden: true,
            component: () => import('@/views/c-wms/goods_category/Category'),
            meta: { title: '货品分类', permission: [ 'warehouse_goods' ] }
          },
          {
            path: 'goods_category/:id',
            name: 'goods_category_edit',
            hidden: true,
            component: () => import('@/views/c-wms/goods_category/table/Edit'),
            meta: { title: '货品分类' }
          },
          {
            path: 'goods',
            name: 'wms_goods_list',
            component: () => import('@/views/c-wms/goods/Goods'),
            meta: { title: '货品', permission: ['warehouse_goods'] }
          },
          {
            path: 'goods_union/:id?',
            name: 'wms_goods_union',
            hidden: true,
            component: () => import('@/views/c-wms/goods/table/UnionWithGoodsList'),
            meta: { title: '套盒内货品' }
          },
          {
            path: 'goods_purchase/:commodity_id?',
            name: 'goods_purchase_list',
            component: () => import('@/views/c-wms/goods_purchase/GoodsPurchase'),
            meta: { title: '货品采购', permission: ['goods_purchase'] }
          },
          {
            path: 'warehouse/inventory',
            name: 'wms_warehouse_detail_count',
            component: () => import('@/views/c-wms/inventory/InventoryList'),
            meta: { title: '库存', permission: ['goods_inventory'] }
          },
          {
            path: 'transfer_inventory',
            name: 'wms_transfer_inventory_list',
            hidden: true,
            component: () => import('@/views/c-wms/trans_inventory/TransInventory'),
            meta: { title: '调拨单', permission: ['transfer_inventory'] }
          },
          {
            path: 'transfer_inventory_detail',
            name: 'wms_transfer_inventory_detail_list',
            hidden: true,
            component: () => import('@/views/c-wms/trans_inventory_detail/TransInventory'),
            meta: { title: '调拨单详情' }
          },
          {
            path: 'goods_loss',
            name: 'wms_goods_loss_list',
            component: () => import('@/views/c-wms/goods_loss/GoodsLoss'),
            meta: { title: '商品灭失', permission: ['goods_loss'] }
          },
          {
            path: 'wms_inventory',
            name: 'wmsInventory',
            component: () => import('@/views/c-wms/take_inventory/TakeInventory'),
            meta: { title: '盘存', permission: ['wms_inventory'] }
          },
          {
            path: 'outflow_list',
            name: 'wms_common_warehouse_outflow_list',
            hidden: true,
            component: () => import('@/views/c-wms/outflow/Outflow'),
            meta: { title: '出库' }
          },
          {
            path: 'warehouse/:id',
            name: 'wms_warehouse_detail',
            hidden: true,
            component: () => import('@/views/c-wms/cargo_space/CargoSpace'),
            meta: { title: '公共库位' }
          },
          {
            path: 'commodity',
            name: 'wms_commodity_list',
            hidden: true,
            component: () => import('@/views/c-wms/commodity/Commodity'),
            meta: { title: '商品' }
          },
          {
            path: 'commodity/:id',
            name: 'wms_commodity_by_goods_list',
            hidden: true,
            component: () => import('@/views/c-wms/commodity/table/CommodityByGoodsIdList'),
            meta: { title: '商品' }
          },
          {
            path: 'wechat_order',
            name: 'wms_wechat_order_list',
            component: () => import('@/views/c-wms/wechat_order/WechatOrder'),
            meta: { title: '微信订单', permission: ['wms_order'] }
          },
          {
            path: 'wechat_order_detail',
            name: 'wms_wechat_order_detail',
            hidden: true,
            component: () => import('@/views/c-wms/wechat_order/table/GoodsList'),
            meta: { title: '微信订单关联商品列表' }
          },
          {
            path: 'picks',
            name: 'picks_list',
            component: () => import('@/views/c-wms/pick/Picks'),
            meta: { title: '拣货单', permission: ['wms_picks'] }
          },
          {
            path: 'picks/:id/goods/',
            name: 'wms_goods_by_picks_list',
            hidden: true,
            component: () => import('@/views/c-wms/pick/table/CommodityByPicksList'),
            meta: { title: '拣货单货品列表' }
          },
          {
            path: 'wms_inventory_detail/:take_inventory_id',
            name: 'wmsInventoryDetail',
            hidden: true,
            component: () => import('@/views/c-wms/take_inventory/table/Read'),
            meta: { title: '盘存详情' }
          },
          {
            path: 'config',
            hidden: true,
            component: () => import('@/views/c-wms/config/Config.vue'),
            name: 'commonStoreConfig',
            meta: {
              title: '配置'
            }
          },
          {
            path: 'wechat_refund_order_goods',
            name: 'wechat_refund_order_goods',
            hidden: true,
            component: () => import('@/views/c-wms/wechat_order/table/RefundOrderGoodsList'),
            meta: { title: '退单入库商品' }
          },
          {
            path: 'upload_app',
            name: 'Upload',
            component: () => import('@/views/c-wms/apk/Upload'),
            meta: { title: '上传APP' }
          }
        ]
      },
      {
        path: '/shop',
        redirect: '/commonshop/coupon',
        component: PageView,
        name: 'applet_wechat',
        meta: { title: '零售端', icon: 'shop', permission: ['shop_coupon', 'shop_discount', 'shop_value_card', 'shop_goods_category', 'shop_goods', 'shop_goods_group', 'shop_user', 'shop_banner', 'shop_notify', 'shop_delivery_address', 'shop_wechat_order', 'shop_pay_method', 'shop_counter_order'] },
        children: [
          {
            path: '/commonshop',
            name: 'commonshopManage',
            redirect: '/commonshop/coupon',
            component: RouteView,
            meta: { title: '通用管理', permission: ['shop_coupon', 'shop_discount', 'shop_value_card'] },
            children: [
              {
                path: 'coupon',
                component: () => import('@/views/a-applet/shop_manage/coupon/CouponList'),
                name: 'Coupon',
                meta: {
                  title: '优惠劵', permission: ['shop_coupon']
                }
              },
              {
                path: 'coupon_create',
                component: () => import('@/views/a-applet/shop_manage/coupon/module/CreateCouponForm'),
                name: 'CouponCreate',
                hidden: true,
                meta: {
                  title: '创建优惠劵'
                }
              },
              {
                path: 'coupon_category',
                component: () => import('@/views/a-applet/shop_manage/coupon/CouponRelatedCategoryList'),
                name: 'CouponCategory',
                hidden: true,
                meta: {
                  title: '优惠劵关联分类'
                }
              },
              {
                path: 'coupon_goods',
                component: () => import('@/views/a-applet/shop_manage/coupon/CouponRelatedGoodsList'),
                name: 'CouponGoods',
                hidden: true,
                meta: {
                  title: '优惠劵关联商品'
                }
              },
              {
                path: 'coupon_user',
                component: () => import('@/views/a-applet/shop_manage/coupon/CouponRelatedUserList'),
                name: 'CouponUser',
                hidden: true,
                meta: {
                  title: '优惠劵领取用户'
                }
              },
              {
                path: 'discount',
                component: () => import('@/views/a-applet/shop_manage/discount/DiscountList'),
                name: 'Discount',
                meta: {
                  title: '商品折扣', permission: ['shop_discount']
                }
              },
              {
                path: 'group_discount',
                hidden: true,
                component: () => import('@/views/a-applet/shop_manage/group_discount/GroupDiscountList'),
                name: 'GroupDiscount',
                meta: {
                  title: '组合优惠', permission: ['shop_discount']
                }
              },
              {
                path: 'group_discount_goods',
                component: () => import('@/views/a-applet/shop_manage/group_discount/GroupDiscountGoodsList'),
                name: 'GroupDiscountGoods',
                hidden: true,
                meta: {
                  title: '组合优惠关联商品'
                }
              },
              {
                path: 'value_card',
                component: () => import('@/views/a-applet/shop_manage/value_card/ValueCardRecordList'),
                name: 'ValueCardRecordList',
                meta: {
                  title: '储值卡', permission: ['shop_value_card']
                }
              },
              {
                path: 'value_card_list',
                component: () => import('@/views/a-applet/shop_manage/value_card/ValueCardList'),
                name: 'ValueCardList',
                hidden: true,
                meta: {
                  title: '储值卡'
                }
              },
              {
                path: 'user_value_card_list',
                component: () => import('@/views/a-applet/shop_manage/value_card/UserBindValueCardList'),
                name: 'UserBindValueCardList',
                hidden: true,
                meta: {
                  title: '用户储值卡'
                }
              },
              {
                path: 'refund/goods',
                component: () => import('@/views/a-applet/shop_manage/RefundGoodsList'),
                name: 'RefundGoodsList',
                hidden: true,
                meta: {
                  title: '退货商品'
                }
              },
              {
                path: 'refund/address',
                component: () => import('@/views/a-applet/shop_manage/RefundAddressList'),
                name: 'RefundAddressList',
                hidden: true,
                meta: {
                  title: '退货地址'
                }
              },
              {
                path: 'order_statistics',
                component: () => import('@/views/a-applet/shop_manage/OrderStatisticsList'),
                name: 'OrderStatisticsAppletList',
                hidden: true,
                meta: {
                  title: '商品销售统计'
                }
              },
              {
                path: 'config',
                component: () => import('@/views/a-applet/shop_manage/MallAppletConfig.vue'),
                name: 'mallAppletConfig',
                hidden: true,
                meta: {
                  title: '配置'
                }
              }
            ]
          },
          // 商品管理
          {
            path: '/applet_goods',
            name: 'goodsAppletManage',
            redirect: '/applet_goods/goods_list',
            component: RouteView,
            meta: {
              title: '商品管理', permission: ['shop_goods_category', 'shop_goods', 'shop_goods_group']
            },
            children: [
              {
                path: 'category',
                name: 'applet_goods_category',
                component: () => import('@/views/a-applet/goods_category/Category'),
                meta: { title: '商品分类', permission: ['shop_goods_category'] }
              },
              {
                path: 'tag',
                name: 'applet_goods_tag',
                component: () => import('@/views/a-applet/shop_goods/goods_tag/GoodsTag'),
                meta: { title: '商品标签' }
              },
              {
                path: 'tag/:id',
                name: 'applet_goods_tag_edit',
                hidden: true,
                component: () => import('@/views/a-applet/shop_goods/goods_tag/table/Edit'),
                meta: { title: '编辑商品标签' }
              },
              {
                path: 'goods_list',
                component: () => import('@/views/a-applet/shop_goods/list'),
                name: 'goodsList',
                meta: {
                  title: '商品列表', permission: ['shop_goods']
                }
              },
              {
                path: 'vip_price',
                hidden: true,
                component: () => import('@/views/a-applet/shop_goods/goods_price/PriceList'),
                name: 'GoodsPrice',
                meta: {
                  title: '商品会员价'
                }
              },
              {
                path: 'count_record',
                hidden: true,
                component: () => import('@/views/a-applet/shop_goods/CountRecordList'),
                name: 'GoodsCountRecord',
                meta: {
                  title: '商品数量变动记录'
                }
              },
              {
                path: 'gallery',
                hidden: true,
                component: () => import('@/views/a-applet/shop_goods/goods_gallery/GalleryList'),
                name: 'GoodsGallery',
                meta: {
                  title: '商品详情页滚动图'
                }
              },
              {
                path: 'group',
                component: () => import('@/views/a-applet/wx_goods_group/GoodsGroupManage'),
                name: 'GoodsGroup',
                meta: {
                  title: '商品组', permission: ['shop_goods_group']
                }
              },
              {
                path: 'group/:id/goods',
                hidden: true,
                component: () => import('@/views/a-applet/wx_goods_group/GroupGoodsList'),
                name: 'GoodsGroupList',
                meta: {
                  title: '组内商品'
                }
              },
              {
                path: 'create',
                component: () => import('@/views/a-applet/shop_goods/create'),
                name: 'goodsCreate',
                hidden: true,
                meta: {
                  title: '商品上架'
                }
              },
              {
                path: 'edit',
                component: () => import('@/views/a-applet/shop_goods/edit'),
                name: 'goodsEdit',
                meta: {
                  title: '商品编辑'
                },
                hidden: true
              }
            ]
          },
          {
            path: '/appletshop',
            name: 'appletshopManage',
            redirect: '/appletshop/shop_user',
            component: RouteView,
            meta: { title: '小程序管理', permission: ['shop_user', 'shop_banner', 'shop_notify', 'shop_delivery_address', 'shop_wechat_order'] },
            children: [
              {
                path: 'shop_user',
                component: () => import('@/views/a-applet/wx_shop_user/UserInformation'),
                name: 'shopUserList',
                meta: {
                  title: '商城用户', permission: ['shop_user']
                }
              },
              {
                path: 'banner',
                component: () => import('@/views/a-applet/wx_banner/BannerManage'),
                name: 'bannerList',
                meta: {
                  title: '首页滚动屏', permission: ['shop_banner']
                }
              },
              {
                path: 'notify/manage',
                component: () => import('@/views/a-applet/shop_manage/NotifyList'),
                name: 'NotifyList',
                meta: {
                  title: '公告管理', permission: ['shop_notify']
                }
              },
              {
                path: 'order_pay_method',
                name: 'shop_order_method_list',
                component: () => import('@/views/a-applet/shop_manage/order_pay_method/OrderPayMethod'),
                meta: { title: '订单支付方式', permission: ['shop_wechat_order'] }
              },
              {
                path: 'order',
                component: () => import('@/views/a-applet/shop_manage/order/OrderList'),
                name: 'OrderAppletList',
                meta: {
                  title: '订单管理', permission: ['shop_wechat_order']
                }
              },
              {
                path: 'main_order',
                component: () => import('@/views/a-applet/shop_manage/order/MainOrderList'),
                name: 'MainOrderAppletList',
                meta: {
                  title: '主订单管理', permission: ['shop_wechat_order']
                }
              },
              {
                path: 'refund',
                component: () => import('@/views/a-applet/shop_manage/refund/RefundList'),
                name: 'AppletRefundList',
                meta: {
                  title: '订单退款',
                  permission: [ 'shop_wechat_order' ]
                }
              },
              {
                path: 'order/detail',
                component: () => import('@/views/a-applet/shop_manage/order/OrderDetail'),
                name: 'OrderAppletDetail',
                hidden: true,
                meta: {
                  title: '订单详情'
                }
              },
              {
                path: 'order/detail_refund',
                component: () => import('@/views/a-applet/shop_manage/OrderDetailRefund'),
                name: 'OrderAppletDetailRefund',
                hidden: true,
                meta: {
                  title: '订单退款'
                }
              },
              {
                path: 'refund/goods',
                component: () => import('@/views/a-applet/shop_manage/RefundGoodsList'),
                name: 'RefundGoodsList',
                hidden: true,
                meta: {
                  title: '退货商品'
                }
              },
              {
                path: 'refund/address',
                component: () => import('@/views/a-applet/shop_manage/RefundAddressList'),
                name: 'RefundAddressList',
                hidden: true,
                meta: {
                  title: '退货地址'
                }
              },
              {
                path: 'order_statistics',
                component: () => import('@/views/a-applet/shop_manage/OrderStatisticsList'),
                name: 'OrderStatisticsAppletList',
                hidden: true,
                meta: {
                  title: '商品销售统计'
                }
              },
              {
                path: 'config',
                component: () => import('@/views/a-applet/shop_manage/MallAppletConfig.vue'),
                name: 'mallAppletConfig',
                hidden: true,
                meta: {
                  title: '配置'
                }
              }
            ]
          }
        ]
      },
      {
        path: '/tools',
        component: PageView,
        redirect: '/tools/tool',
        name: 'tools',
        hidden: true,
        meta: { title: '工具', icon: 'tool', keepAlive: true, permission: ['tools'] },
        children: [
          {
            path: '/tools/tool/upload',
            name: 'Upload',
            component: () => import('@/views/a-tools/Upload'),
            meta: { title: '上传文件' }
          },
          {
            path: '/tools/unit',
            name: 'Units',
            component: () => import('@/views/a-tools/unit/CommonUnit'),
            meta: { title: '单位' }
          },
          {
            path: '/tools/country',
            name: 'Countries',
            component: () => import('@/views/a-tools/country/CommonCountry'),
            meta: { title: '国家' }
          },
          {
            path: '/tools/currency',
            name: 'Currencies',
            component: () => import('@/views/a-tools/currency/CommonCurrency'),
            meta: { title: '货币' }
          }
        ]
      },
      {
        path: '/account/settings',
        component: PageView,
        redirect: '/account/settings/base',
        hidden: true,
        name: 'AccountSetting',
        meta: { title: '个人页', icon: 'user', keepAlive: true, permission: [ 'user' ] },
        children: [
          {
            path: '/account/settings/base',
            name: 'BaseSettings',
            component: () => import('@/views/account/settings/BaseSetting'),
            meta: { title: '基本设置', hidden: true, permission: [ 'user' ] }
          }
        ]
      }
    ]
  },
  {
    path: '*', redirect: '/404', hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },

  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }

]
